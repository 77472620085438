import React from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import companyStyles from './company.module.scss';

const companyPage = ({ data, location }) => {
  const { content, content2, image } = data.contentfulPage;
  return (
    <Layout>
      <SEO title="LATERMITE.COM" pathname={location.pathname} />
      <div className={companyStyles.container}>
        <div className="columns">
          <div className="column is-7">
            <div className={companyStyles.wrapper}>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
          <div className="column">
            <Img
              style={{ margin: '0 auto ' }}
              fluid={image.fluid}
              alt="Tycecc Termite Control"
            />
          </div>
        </div>
        <div className={companyStyles.wrapper}>
          <div
            dangerouslySetInnerHTML={{
              __html: content2.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

companyPage.propTypes = {
  data: propTypes.shape({
    contentfulPage: propTypes.shape({
      title: propTypes.string,
      content: propTypes.shape({
        childMarkdownRemark: propTypes.object,
      }),
      content2: propTypes.shape({
        childMarkdownRemark: propTypes.object,
      }),
      image: propTypes.shape({
        fluid: propTypes.object,
      }),
    }),
  }),
  location: propTypes.shape({
    pathname: propTypes.string,
  }),
};

companyPage.defaultProps = {
  data: {},
  location: {},
};

export default companyPage;

export const pageQuery = graphql`
  query {
    contentfulPage(slug: { eq: "about-us" }) {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      content2 {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  }
`;
